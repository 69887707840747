import { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { NafathRequestStatus } from '../Utils/constants';

export type Step = {
  [key in number]: string;
};

export type BusinessSteps = Step[];
export type IndividualSteps = Step[];

export interface ErrorMessages {
  [key: string]: string;
}

export type User = {
  status: 'pending' | 'active' | '';
  role: 'user' | 'admin' | '';
  termsConditions: string;
  isActive: boolean;
  id: number;
  email: string;
  arabicFirstName: string;
  arabicLastName: string;
  arabicAddress: string;
  accountName: string;
  address: string;
  city: string;
  identificationNumber: string;
  postalCode: string;
  province: string;
  firebaseId: string;
  firstName: string;
  lastName: string;
  customerId: number;
  updatedAt: string;
  createdAt: string;
  phone: string | null;
};

export interface UsersState {
  totalItems: number;
  totalPages: number;
  currentPage: number;
  users: User[];
}

export const defaultUser: User = {
  status: '',
  role: '',
  termsConditions: '',
  isActive: false,
  id: 0,
  email: '',
  arabicFirstName: '',
  arabicLastName: '',
  arabicAddress: '',
  accountName: '',
  address: '',
  city: '',
  identificationNumber: '',
  postalCode: '',
  province: '',
  firebaseId: '',
  firstName: '',
  lastName: '',
  customerId: 0,
  updatedAt: '',
  createdAt: '',
  phone: null,
};

interface FileType {
  id?: number;
  fileName?: string;
  fileType?: string;
  documentType?: string;
  filePath?: string;
  customerId?: number;
  createdAt?: string;
  updatedAt?: string;
  fileSize?: number | null;
}

export type Customer = {
  status: 'pending' | 'active' | 'suspended' | 'cancelled' | '';
  step:
    | 'basic-info'
    | 'support-package'
    | 'upload-documents'
    | 'billing-email'
    | 'payment-info'
    | 'onboarding-completed'
    | 'google-info'
    | 'terms-conditions'
    | 'success-creation'
    | '';
  id: number;
  name: string;
  accountType: 'individual' | 'business' | '';
  updatedAt: string; // ISO date string
  createdAt: string; // ISO date string
  activationDate: string | null;
  subscriptionDate: string | null;
  cancellationDate: string | null;
  userId: number | null;
  qoyodId: number | null;
  accountName: string | null;
  businessName: string | null;
  firstName: string | null;
  lastName: string | null;
  phone: string | null;
  arabicBusinessName: string | null;
  arabicAddress: string | null;
  domain: string | null;
  organizationType: string | null;
  vatId: string | null;
  identificationNumber: string | null;
  address: string | null;
  package: 'basic' | 'standard' | 'enhanced' | null;
  city: string | null;
  postalCode: string | null;
  province: string | null;
  gcpSecurityGroup: string | null;
  gcpSubBillingAccount: string | null;
  gcpSubBillingAccountId: string | null;
  oldGcpSubBillingAccount: string | null;
  gcpSecurityGroupVerified: boolean;
  threshold: number | null;
  files: FileType[];
  user: User;
  desktopVersion: boolean;
  contactEmail: string;
  paymentMethod: {
    id: number | null;
    customerId: number | null;
    paymentIdentifier: string | null;
    last4: string | null;
    expiryDate: string | null;
    paymentMethod: string | null;
    status: string | null;
    type: string | null;
    deleted: boolean | null;
    createdAt: string | null;
    updatedAt: string | null;
  };
  identificationNumberVerified: boolean;
};

export type CustomerObject = {
  customer: Customer;
};

export const defaultCustomer: Customer = {
  status: '',
  step: '',
  id: 0,
  name: '',
  accountType: '',
  updatedAt: '',
  createdAt: '',
  activationDate: '',
  cancellationDate: '',
  subscriptionDate: '',
  userId: null,
  qoyodId: null,
  accountName: 'cntxt-online-CR#-',
  businessName: null,
  arabicBusinessName: null,
  arabicAddress: null,
  domain: null,
  organizationType: 'private',
  vatId: null,
  firstName: null,
  lastName: null,
  phone: null,
  identificationNumber: null,
  address: null,
  package: null,
  city: '',
  postalCode: null,
  province: '',
  gcpSecurityGroup: null,
  gcpSubBillingAccount: null,
  gcpSubBillingAccountId: null,
  oldGcpSubBillingAccount: null,
  gcpSecurityGroupVerified: false,
  desktopVersion: false,
  threshold: null,
  files: [],
  user: {
    status: '',
    role: '',
    termsConditions: '',
    isActive: false,
    id: 0,
    city: '',
    arabicFirstName: '',
    arabicLastName: '',
    arabicAddress: '',
    accountName: 'cntxt-online-ind-nationalidoriqama#-',
    email: '',
    address: '',
    firebaseId: '',
    firstName: '',
    lastName: '',
    customerId: 0,
    postalCode: '',
    identificationNumber: '',
    province: '',
    updatedAt: '',
    createdAt: '',
    phone: null,
  },
  contactEmail: '',
  paymentMethod: {
    id: 0,
    customerId: 0,
    paymentIdentifier: '',
    last4: '',
    expiryDate: '',
    paymentMethod: '',
    status: '',
    type: '',
    deleted: false,
    createdAt: '',
    updatedAt: '',
  },
  identificationNumberVerified: false,
};

export type CustomerResult = { data: { customer: Customer; nafathEnabled: boolean } };

export type IndividualData = {
  identificationNumber: string | null;
  firstName: string | null;
  lastName: string | null;
  arabicFirstName: string | null;
  arabicLastName: string | null;
  arabicAddress: string | null;
  accountName: string | null;
  contactEmail: string | null;
  phone: string | null;
  address: string | null;
  city: string | null;
  postalCode: string | null;
  province: string | null;
};

export type BusinessData = {
  businessName: string | null;
  arabicBusinessName: string | null;
  domain: string | null;
  organizationType: string | null;
  identificationNumber: string | null;
  accountName: string | null;
  vatId: string | null;
  address: string | null;
  arabicAddress: string | null;
  city: string | null;
  province: string | null;
  firstName: string | null;
  lastName: string | null;
  phone: string | null;
  postalCode: string | null;
};

export type SetIsLoadingType = (value: boolean | ((prevValue: boolean) => boolean)) => void;

export type SetErrorMessageType = (value: string | ((prevValue: string) => string)) => void;

export type AxiosErrorResponse = {
  response?: {
    status?: number;
    code?: number;
  };
  message?: string;
};

export interface FieldErrors {
  businessName: boolean;
  arabicBusinessName: boolean;
  organizationType: boolean;
  identificationNumber: boolean;
  accountName: boolean;
  address: boolean;
  arabicAddress: boolean;
  city: boolean;
  province: boolean;
  firstName: boolean;
  lastName: boolean;
  phone: boolean;
  postalCode: boolean;
  contactEmail: boolean;
}

export interface ContactFieldErrors {
  identificationNumber: boolean;
  firstName: boolean;
  lastName: boolean;
  arabicFirstName: boolean;
  arabicLastName: boolean;
  arabicAddress: boolean;
  accountName: boolean;
  phone: boolean;
  address: boolean;
  city: boolean;
  postalCode: boolean;
  province: boolean;
  contactEmail: boolean;
}

export type Documents = {
  registration: File | string | null;
  vat: File | string | null;
  address: File | string | null;
};

export type SubscriptionProps = {
  package: string;
  status: string;
  activationDate: string | null;
  cancellationDate: string | null;
  subscriptionDate: string | null;
  createdAt: string;
};

export type UnbilledProps = {
  currency: string;
  unbilledAmount: string;
};

export type DashboardProps = {
  unbilledAmount: null | number;
  nextCharge: string;
  lastInvoice: {
    id: number;
    customerId: number;
    qoyodInvoiceId: null | string;
    description: null | string;
    billingMonth: string;
    thresholdInvoice: string;
    currency: string;
    grossAmount: number;
    chargedAmount: number;
    discount: null | number;
    netAmount: number;
    resellerMargin: null | number;
    netPayableGoogle: null | number;
    supportLineItem: null | string;
    status: string;
    invoiceUrl: null | string;
    qoyod_reference: null | string;
    paymentFailedAttempts: number | null;
    lastPaymentFailureReason: null | string;
    paymentAttempts: number | null;
    last_bill_date: string;
    issuedAt: null | string;
    dueDate: string | null;
    chargedAt: null | string;
    createdAt: string;
    updatedAt: string;
  };
};

export type Invoice = {
  id: number;
  customerId: number;
  qoyodInvoiceId: string;
  billingMonth: string;
  currency: string;
  grossAmount: number;
  chargedAmount: number;
  discount: number;
  netAmount: number;
  resellerMargin: number;
  netPayableGoogle: number;
  status: string;
  invoiceUrl: string;
  qoyod_reference: string;
  paymentFailedAttempts: number | null;
  lastPaymentFailureReason: string | null;
  paymentAttempts: number | null;
  issuedAt: string;
  dueDate: string | null;
  chargedAt: string;
  createdAt: string;
  updatedAt: string;
  thresholdInvoice: string;
};

export interface InvoicesState {
  totalItems: number;
  totalPages: number;
  currentPage: number;
  invoices: Invoice[];
}

export const defaultInvoice: Invoice = {
  id: 0,
  customerId: 0,
  qoyodInvoiceId: '',
  billingMonth: '',
  currency: '',
  grossAmount: 0,
  chargedAmount: 0,
  discount: 0,
  netAmount: 0,
  resellerMargin: 0,
  netPayableGoogle: 0,
  status: '',
  invoiceUrl: '',
  qoyod_reference: '',
  paymentFailedAttempts: null,
  lastPaymentFailureReason: null,
  paymentAttempts: null,
  issuedAt: '',
  dueDate: null,
  chargedAt: '',
  createdAt: '',
  updatedAt: '',
  thresholdInvoice: '',
};

export type CustomerProps = {
  name: string;
  domain: string;
  account_type: string;
  status: string;
  action: number;
};

export type DeleteUsersParams = {
  userId: number | string;
  currentPage: number;
  limit: number;
  setIsLoading: SetIsLoadingType;
  setErrorMessage: SetErrorMessageType;
  navigate: ReturnType<typeof useNavigate>;
  isAdmin: boolean;
  customerId?: number;
};

export type InviteUserParams = {
  email: string;
  currentPage: number;
  limit: number;
  setIsLoading: SetIsLoadingType;
  setErrorMessage: SetErrorMessageType;
  navigate: ReturnType<typeof useNavigate>;
  setIsModalOpen: SetIsLoadingType;
  isAdmin: boolean;
  customerId?: number;
};

export type SaveCardDetailsParams = {
  paymentId: string;
  id: string;
  last4: string;
  expiryDate: string;
  status: string;
  type: string;
  navigate: ReturnType<typeof useNavigate>;
  setIsLoading: SetIsLoadingType;
  setErrorMessage: SetErrorMessageType;
  isAdmin: boolean;
  customerId?: number;
  isOnboarding?: boolean;
  setIsModalOpen?: SetIsLoadingType;
  cardHolderName: string;
};

export type PaymentApiResponse = {
  id: string;
  last_four: string;
  brand: string;
  verification_url: string;
  month: string;
  year: string;
  name: string;
};

export interface Card {
  id: string;
  status: string;
  paymentIdentifier: string;
  last4: string;
  expiryDate: string;
  type: string;
  cardHolderName: string;
}

export type NafathApiResponse = {
  data: {
    id: number;
    customerId: number;
    requestId: string;
    transId: string;
    random: number;
    status: NafathRequestStatus;
    createdAt: Date;
  };
};

// API response type when Nafath service is disabled
export type IqamaApiResponse = {
  data: {
    customer: number;
  };
};

export type SetState<T> = Dispatch<SetStateAction<T>>;
